<template>
  <main>
    <section class="section">
      <div class="container2">
        <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <sqr-crumb name="broker" :bid="bid">{{$t('breadcrumb_broker', broker)}}</sqr-crumb>
            <sqr-crumb name="relations" :bid="bid" label="breadcrumb_relations"/>
            <sqr-crumb name="relation" :bid="bid" :rid="rid">{{$t('breadcrumb_relation', relation)}}</sqr-crumb>
            <sqr-crumb name="relation-offers" :bid="bid" :rid="rid" label="breadcrumb_relation_offers"/>
            <sqr-crumb name="relation-offer-add-vehicule" :bid="bid" :rid="rid" label="breadcrumb_relation_offers_add_vehicule"/>
          </ul>
        </nav>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <h3 class="title is-3">{{$t('offer_add_relation')}}</h3>
        <sqr-input-display :value="relation.name"/>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <h3 class="title is-3">{{$t('offer_add_characteristic')}}</h3>
        <div class="columns">
          <div class="column">
            <sqr-input-text class="field" label="offer_vehicule_brand" :value="record.vehiculeBrand" @change="fieldSet({field: 'vehiculeBrand', value: $event})"/>
            <sqr-input-text class="field" label="offer_vehicule_type" :value="record.vehiculeType" @change="fieldSet({field: 'vehiculeType', value: $event})"/>
            <sqr-input-text class="field" label="offer_vehicule_registration_number" :value="record.vehiculeRegistrationNumber" @change="fieldSet({field: 'vehiculeRegistrationNumber', value: $event})"/>
            <sqr-input-text class="field" label="offer_vehicule_frame" :value="record.vehiculeFrame" @change="fieldSet({field: 'vehiculeFrame', value: $event})"/>
            <sqr-input-text class="field" label="offer_vehicule_release" :value="record.vehiculeRelease" @change="fieldSet({field: 'vehiculeRelease', value: $event})"/>
          </div>
          <div class="column">
            <sqr-input-text class="field" label="offer_vehicule_displacement" :value="record.vehiculeDisplacement" @change="fieldSet({field: 'vehiculeDisplacement', value: $event})"/>
            <sqr-input-text class="field" label="offer_vehicule_approval" :value="record.vehiculeApproval" @change="fieldSet({field: 'vehiculeApproval', value: $event})"/>
            <sqr-input-text class="field" label="offer_vehicule_price" :value="record.vehiculePrice" @change="fieldSet({field: 'vehiculePrice', value: $event})"/>
            <sqr-input-text class="field" label="offer_vehicule_weight" :value="record.vehiculeWeight" @change="fieldSet({field: 'vehiculeWeight', value: $event})"/>
            <sqr-input-text class="field" label="offer_vehicule_accessories" :value="record.vehiculeAccessories" @change="fieldSet({field: 'vehiculeAccessories', value: $event})"/>
          </div>
          <div class="column">
            <sqr-input-text class="field" label="offer_vehicule_garage" :value="record.vehiculeGarage" @change="fieldSet({field: 'vehiculeGarage', value: $event})"/>
            <sqr-input-text class="field" label="offer_vehicule_usage" :value="record.vehiculeUsage" @change="fieldSet({field: 'vehiculeUsage', value: $event})"/>
            <sqr-input-text class="field" label="offer_vehicule_km_per_year" :value="record.vehiculeKmPerYear" @change="fieldSet({field: 'vehiculeKmPerYear', value: $event})"/>
            <sqr-input-text class="field" label="offer_vehicule_km_odometer" :value="record.vehiculeKmOdometer" @change="fieldSet({field: 'vehiculeKmOdometer', value: $event})"/>
            <sqr-input-text class="field" label="offer_vehicule_leasing" :value="record.vehiculeLeasing" @change="fieldSet({field: 'vehiculeLeasing', value: $event})"/>
          </div>
          <div class="column">
            <sqr-input-text class="field" label="offer_vehicule_plates" :value="record.vehiculePlates" @change="fieldSet({field: 'vehiculePlates', value: $event})"/>
            <sqr-input-text class="field" label="offer_vehicule_current_insurer" :value="record.vehiculeCurrentInsurer" @change="fieldSet({field: 'vehiculeCurrentInsurer', value: $event})"/>
            <sqr-input-text class="field" label="offer_vehicule_personal_effects_value" :value="record.vehiculePersonalEffectsValue" @change="fieldSet({field: 'vehiculePersonalEffectsValue', value: $event})"/>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <h3 class="title is-3">{{$t('offer_add_risks')}}</h3>
        <div class="check-col">
          <div class="field" v-for="risk in risks" :key="risk">
            <label class="checkbox"><input type="checkbox" name="risks" v-model="recordRisks" :value="risk"> {{risk}}</label>
          </div>
        </div>
        <!-- <pre>{{record.risks}}</pre> -->
      </div>
    </section>
    <section class="section">
      <div class="container">
        <h3 class="title is-3">{{$t('offer_add_recipients')}}</h3>
        <div class="columns">
          <div class="column">
            <table class="table is-hoverable">
              <tbody>
              <tr v-for="contact in contacts" :key="contact.email" @click="toggleContact(contact)">
                <td><input type="checkbox" name="contacts" v-model="recordContacts" :value="contact"/></td>
                <td>{{contact.company}}</td>
                <td class="is-hidden-mobile">{{contact.givenName}} {{contact.familyName}}</td>
                <td>{{contact.email}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="column">
            <sqr-input-email class="field" label="offer_cc" :value="record.cc" @change="fieldSet({field: 'cc', value: $event})"/>
            <sqr-input-email class="field" label="offer_reply_to" :value="record.replyTo" @change="fieldSet({field: 'replyTo', value: $event})"/>
            <sqr-input-email class="field" label="offer_test" :value="record.test" @change="fieldSet({field: 'test', value: $event})"/>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="is-pulled-right">
          <sqr-button icon="envelope" label="offer_send_all" @click="saveSendAndGo()" color="primary" size="large"/>
        </div>
      </div>
    </section>
  </main>
</template>

<style lang="scss" scoped>
  .check-col {
    column-gap: 2rem;
    column-rule-width: 1px;
    column-rule: 1px solid lightgray;
    columns: 18em;
  }
</style>

<script>
import relation from './relation';
import SqrInputDisplay from '@/sqrd/SqrInputDisplay';
import SqrInputText from '@/sqrd/SqrInputText';
import SqrInputDate from '@/sqrd/SqrInputDate';
import SqrInputEmail from '@/sqrd/SqrInputEmail';
import { mapActions, mapMutations, mapGetters } from 'vuex';

import buildRef from '@/utils/buildRef';
import nanoRef from '@/utils/nanoRef';

function buildTo (contact) {
  const name = [
    contact.givenName,
    contact.familyName,
    contact.company
  ].filter(v => Boolean(v)).join(' ');
  return `${name} <${contact.email}>`;
}

export default {
  name: 'RelationOfferVehiculeAdd',
  mixins: [relation],
  components: {SqrInputDisplay, SqrInputText, SqrInputDate, SqrInputEmail},
  computed: {
    ...mapGetters('offer', ['record', 'saving', 'saveError']),
    recordRisks: {
      get () {
        return this.record && this.record.risks || [];
      },
      set (value) {
        this.fieldSet({field: 'risks', value: value});
      }
    },
    recordContacts: {
      get () {
        return this.record && this.record.contacts || [];
      },
      set (value) {
        this.fieldSet({field: 'contacts', value: value});
      }
    }
  },
  mounted () {
    this.init({
      path: ['brokers', this.bid, 'offers'],
      record: {
        to: '',
        cc: '',
        test: '',
        relation: {},
        risks: [],
        contacts: [],
        agent: {},
      }
    })
  },
  data () {
    return {
      risks: [
        'RC avec protection du bonus',
        'Casco collision avec protection du bonus, franchise 500 CHF',
        'Casco collision avec protection du bonus, franchise 1000 CHF',
        'Casco partielle',
        'Bris de glace Normal',
        'Bris de glace Plus',
        'Fouines',
        'Faute grave',
        'Dommages parc',
        'Accidents des occupants',
        'Dépannage/Assistance',
        'Front Assist',
        'Franchissement ligne blanche',
        'Assist. chgt voie (yc angle mort)'
      ],
      contacts: [
        {company: 'Allianz', civility: 'Monsieur,', givenName: 'Yassin', familyName: 'Zehani', email: 'yassin.zehani@allianz-suisse.ch'},
        {company: 'AXA', civility: 'Madame, Monsieur,', givenName: '', familyName: '', email: 'courtiers.p@axa-winterthur.ch'},
        {company: 'Generali', civility: 'Madame, Monsieur,', givenName: 'Zoé', familyName: 'Cretegny', email: 'zoe.cretegny@generali.com'},
        {company: 'Helvetia', civility: 'Madame, Monsieur,', givenName: '', familyName: '', email: 'nonvie@helvetia.ch'},
        {company: 'La Bâloise', civility: 'Madame, Monsieur,', givenName: '', familyName: '', email: 'courtier.riviera@baloise.ch'},
        {company: 'La Bâloise', civility: 'Monsieur,', givenName: '', familyName: '', email: 'ludovic.moulin@baloise.ch'},
        {company: 'La Mobilière', civility: 'Monsieur,', givenName: 'Sébastien', familyName: 'Givel', email: 'sebastien.givel@mobi.ch'},
        {company: 'La Mobilière', civility: 'Monsieur,', givenName: 'Daniel', familyName: 'Kursner', email: 'daniel.kursner@mobi.ch'},
        {company: 'Vaudoise', civility: 'Monsieur,', givenName: 'Joni', familyName: 'Widmer', email: 'jwidmer@vaudoise.ch'},
        {company: 'Zurich', civility: 'Madame, Monsieur,', givenName: '', familyName: '', email: 'partenaires-lausanne@zurich.ch'}
      ]
    }
  },
  methods: {
    ...mapMutations('offer', ['fieldSet']),
    ...mapActions('offer', ['init', 'save']),
    async saveSendAndGo() {
      try {

        this.fieldSet({field: 'type', value: 'vehicule'});
        this.fieldSet({field: 'created', value: new Date().toISOString()});
        this.fieldSet({field: 'relationId', value: this.rid});
        this.fieldSet({field: 'relation', value: this.relation});
        this.fieldSet({field: 'reference', value: nanoRef()});

        await this.save();
        const path = ['brokers', this.bid, 'emails'];
        const ref = buildRef(path);
        await Promise.all(this.record.contacts.map(contact => {
          ref.add({
            relationId: this.rid,
            to: this.record.test || buildTo(contact),
            cc: this.record.cc,
            replyTo: this.record.replyTo,
            template: {
              templateId: 'offer-vehicule',
              relationId: this.rid,
              data: {
                ...this.record
              }
            }
          });
        }));
        this.$router.push({name: 'relations-emails', params: {bid: this.bid, rid: this.rid}});
      } catch (error) {
        console.log(error);
      }
    },
    toggleContact(contact) {
      const contacts = [...this.record.contacts];
      const index = contacts.findIndex(c => c == contact);
      if (index >= 0) {
        contacts.splice(index, 1);
      } else {
        contacts.push(contact);
      }
      this.fieldSet({field: 'contacts', value: contacts});
    }
  }
}
</script>
